import { instance } from "@/app/api/api"
import { useSnackbarStore } from "@/store/snackbarStore"
import { useProfileStore } from "@/store/profileStore"
import axios from "axios"
import { AccountHttpResponse, VerifyAuthenticationCodeHttpResponse } from "./type"
import { BaseResponse } from "../baseResponse-api-models"

export const postSendVerificationCode = async (
  email: string,
  isSignup?: boolean,
): Promise<boolean> => {
  const url = `/api/v1/public/accounts:${
    isSignup
      ? "sendEmailVerifyAuthenticationCode" // 회원가입
      : "sendPasswordResetAuthenticationCode" // 비밀번호 찾기
  }`

  try {
    await instance.post(url, { email })

    return true
  } catch (error) {
    return false
  }
}

export const postVerifyAuthenticationCode = async (
  email: string,
  code: string,
  isSignup?: boolean,
): Promise<string | null> => {
  const url = `/api/v1/public/accounts:${
    isSignup
      ? "confirmEmailVerifyAuthenticationCode" // 회원가입
      : "confirmPasswordResetAuthenticationCode" // 비밀번호 찾기
  }`

  try {
    const {
      data: { body },
    } = await instance.post<BaseResponse<VerifyAuthenticationCodeHttpResponse>>(url, {
      email,
      authenticationCode: code,
    })

    return body.token
  } catch (error) {
    return null
  }
}

export const signUp = async (
  data,
  invitationCode: string | null,
): Promise<boolean> => {
  const url = `/api/v1/public/accounts:signUp${
    invitationCode ? `?invitationCode=${invitationCode}` : ""
  }`

  try {
    await instance.post(url, data)

    return true
  } catch (error) {
    return false
  }
}

export const signIn = async (
  email: string,
  password: string,
  invitationCode: string | null,
): Promise<void> => {
  const loginInstance = axios.create({
    baseURL: process.env.NEXT_PUBLIC_API_URL,
    headers: {
      Accept: "application/json",
    },
    withCredentials: true,
  })

  const url =
    "/api/v1/public/accounts:signIn" +
    (invitationCode ? `?invitationCode=${invitationCode}` : "")

  loginInstance
    .post(url, {
      email: email,
      password: password,
    })
    .then(res => {
      const { accountId, isNew } = res.data.body
      let url = "/login-success"

      if (accountId) {
        url += `?accountId=${accountId}`
      }

      if (isNew !== undefined) {
        const prefix = accountId ? "&" : "?"
        url += `${prefix}isNew=${isNew}`
      }
      const prefix = accountId || isNew !== undefined ? "&" : "?"
      url += `${prefix}authorizedMethod=email`

      window.location.href = url
    })
    .catch(error => {
      const errorMessage =
        error.response.data?.body?.message ||
        error.response.data?.message ||
        "알 수 없는 오류가 발생했습니다."

      useSnackbarStore.getState().openSnackbar(errorMessage, "danger")
    })
}

// 로그인 - 비밀번호 찾기
export const putResetPassword = async (
  password: string,
  token: string,
): Promise<boolean> => {
  const url = "/api/v1/public/accounts:resetPassword"

  try {
    await instance.put(url, { password, token })

    return true
  } catch (error) {
    return false
  }
}

// 설정: 비밀번호 변경
export const putChangePassword = async (
  currentPassword: string,
  newPassword: string,
): Promise<boolean> => {
  const accountId = useProfileStore.getState().profile?.account.id
  const url = `/api/v1/accounts/${accountId}:resetPassword`

  try {
    await instance.put(url, { currentPassword, newPassword })

    return true
  } catch (error) {
    return false
  }
}

export const putAccount = async (
  displayName: string,
): Promise<AccountHttpResponse> => {
  const accountId = useProfileStore.getState().profile?.account.id
  const url = `/api/v1/accounts/${accountId}`

  const { data } = await instance.put<AccountHttpResponse>(url, {
    displayName,
  })

  return data
}
