import { useFormContext, Controller } from "react-hook-form"
import { Input } from "@nextui-org/react"
import { InputProps } from "@nextui-org/react"
import { RegisterOptions } from "react-hook-form"

interface Props extends InputProps {
  name: string
  rules?: Omit<
    RegisterOptions,
    "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled"
  >
  nextFocus?: string
  type?: string
  viewOnly?: boolean
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void
}

export default function TextField({
  name,
  rules,
  nextFocus,
  type,
  viewOnly,
  onKeyDown,
  ...props
}: Props) {
  const { control, setFocus } = useFormContext()

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field, fieldState }) => (
        <Input
          ref={field.ref}
          value={field.value ?? ""}
          size="lg"
          onBlur={() => {
            if (field.value && typeof field.value === "string") {
              queueMicrotask(() => {
                field.onChange(field.value.trim())
              })
            }
          }}
          onValueChange={value => {
            field.onChange(type === "number" ? Number(value) : value)
          }}
          onKeyDown={e => {
            if (e.key === "Enter") {
              if (e.isComposing || e.keyCode === 229) return

              nextFocus ? setFocus(nextFocus) : onKeyDown && onKeyDown(e)
            } else {
              onKeyDown && onKeyDown(e)
            }
          }}
          labelPlacement="outside"
          variant="bordered"
          classNames={{
            base: `bolta-input ${props.className ?? ""}`,
            inputWrapper: `${viewOnly ? "!cursor-not-allowed" : ""}`,
            input: `${viewOnly ? "cursor-not-allowed" : ""}`,
          }}
          type={type === "password" ? "password" : undefined}
          isInvalid={!!fieldState.error}
          errorMessage={fieldState.error?.message}
          {...Object.fromEntries(
            Object.entries(props).filter(([key]) => key !== "className"),
          )}
        />
      )}
    />
  )
}
